<template>
  <v-layout wrap justify-center>
    <Header titulo="Preguntas Frecuentes" :imagen="$store.state.headerOtras" />
    <v-flex xs12 class="my-4">
      <v-expansion-panels popout v-if="!$store.state.cargando">
        <v-expansion-panel v-for="(pregunta, i) in Preguntas" :key="i">
          <v-expansion-panel-header class="font-weight-bold title"
            >{{ pregunta.id }} -
            {{ pregunta.pregunta }}</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            {{ pregunta.respuesta }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-flex>
  </v-layout>
</template>

<script>
import Header from "../components/Header.vue";
export default {
  metaInfo: {
    title: "Preguntas Frecuentes | Ofimania",
  },
  created() {
    this.$store.state.cargando = true;
    this.axios
      .get("https://www.ofimania.com/API/Pregunta/Get")
      .then((response) => {
        this.Preguntas = response.data;
        this.$store.state.cargando = false;
      })
      .catch((error) => {
        this.$store.state.cargando = false;
        this.$store.state.alerta = {
          estado: true,
          tipo: "error",
          titulo: "Error de Conexion",
          info: "Verifique su Conexion a Internet",
        };
      });
  },
  data() {
    return {
      Preguntas: [],
    };
  },
  components: {
    Header,
  },
};
</script>

<style></style>
